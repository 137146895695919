@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Dana;
    src: url(./fonts/DanaFaNum.woff2);
  }
  @font-face {
    font-family: Morabba;
    src: url(./fonts/Morabba.woff2);
  }
  @font-face {
    font-family: Plasma;
    src: url(./fonts/PlasmaSquareDots.woff2);
  }
  @font-face {
    font-family: Dosis;
    src: url(./fonts/Dosis.ttf);
  }

  html,
  body {
    @apply  bg-primary font-Dana no-scrollbar;
  }
}

@layer components {
  .inner__shadow {
    @apply bg-primary;
    box-shadow: 6px 6px 10px #07183080, -6px -6px 10px #0f305e80, inset 9px 9px 8px #071830, inset -9px -9px 8px #0f305e;
  }
  .outer__shadow {
    background: linear-gradient(145deg, #0c274c, #0a2040);
    box-shadow: 6px 6px 10px #071830, -6px -6px 10px #0f305e;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
